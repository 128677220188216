import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
import { FormJoin } from '../../components/FormJoin';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h2" components={components}>{`Určite sa nájde mnoho vecí, o ktorých sme doteraz nepremýšľali. Máš nápad, ktorý spĺňa naše hodnoty a rád by si ho realizoval?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Našou snahou je vytvorenie priestoru, kde bude mať každý možnosť sa informovať, vzdelávať, rozvíjať a posúvať seba či svoj biznis vpred prostredníctvom nových technológií, kryptomien, slobodnejšej spoločnosti a podmienok pre prácu, podnikanie či kariéru.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Spoločne ako komunita dosiahneme viac. Stotožňuješ sa s touto myšlienkou?`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Ako sa zapojiť?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Napíš nám o svojom nápade a spoločne nájdime možnosti, ako ho uskutočniť.`}</MDXTag>
      <FormJoin category="Vlastny napad">
  Napíš nám svoju predstavu. Si z Košíc alebo tu vieš dochádzať?
      </FormJoin>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      